import React, {Component } from 'react';
import { observer } from 'mobx-react';

import imageURLs from '../../../sharedFiles/ImagesURLs';

import DropDownComponent from '../../../sharedComponents/dropDown/dropDown';
import OnlineOperatorsComponent from '../../../sharedComponents/operatorsList/OnlineOperators';
import CurrentChatsComponent from '../../../sharedComponents/currentChats/CurrentChats';

const SideSubMenuComponent = observer(class SideSubMenuComponent extends Component {

	constructor (props) {
		super(props);
		this.state = {websiteUid:this.props.appstore.selectedWebisiteUid,
					 website_logo:this.props.appstore.selectedWebisiteLogo,
					 website_name:this.props.appstore.selectedWebisitename}
		
    }

	componentWillMount(){
	}

	componentWillReceiveProps(nextProps){
	}
	componentDidMount(){		
		//added to remove last selected submenu 
		this.props.sideMenuStore.submenuSelectedItem = "";
			this.props.submenuData.map(function(submenu, menuIndex){
				//console.log(submenu.subMenuLabel)
					submenu.active = false;
					submenu.openCount = 0;
			});
	
	}

	componentDidUpdate(prevProps, prevState) { 
		//console.log(prevState.website_logo +" == "+this.props.appstore.selectedWebisiteLogo +" == "+prevState.website_name +" == "+prevState.website_name);
		if (prevState.websiteUid !== this.props.appstore.selectedWebisiteUid 
			|| prevState.website_logo!==this.props.appstore.selectedWebisiteLogo 
			|| prevState.website_name!==this.props.appstore.selectedWebisitename) { 
			let updated=true;
			this.props.sideMenuStore.logoDrpDown = this.props.sideMenuStore.getWesiteswithLogo(this.props.appstore.userInfo.allwebistes,updated);
			this.props.sideMenuStore.selectedSite= this.props.sideMenuStore.logoDrpDown.find(option => option.website_uid === this.props.appstore.selectedWebisiteUid);
			this.setState({websiteUid:this.props.appstore.selectedWebisiteUid});			  
		}
	}

	getDefaultSelectedItem(submenu){

		if(submenu.menuList.length > 0){
			submenu.active = !submenu.active;
			if(submenu.active) {
				this.props.sideMenuStore.selectedMenu.menuOpenCount++;
				this.setOpenCount(submenu);
			}
			else {
				submenu.openCount = 0;
				this.props.sideMenuStore.selectedMenu.menuOpenCount--;
			}
		}
		else{
			this.props.submenuData.map(function(subMenu,index){
				subMenu.active = false;
			})
			submenu.active = true;
		}

		if(submenu.subMenuLabel === "Online Operators"){
			//this.props.appstore.onlineSubmenuOperators =  Math.random();
		}
		
	}
	setOpenCount(menu) {
		let self = this;
		this.props.submenuData.map(function(submenu, menuIndex){
			if(submenu.openCount > 1) {
				submenu.active = false;
				submenu.openCount = 0;
				self.props.sideMenuStore.selectedMenu.menuOpenCount--;
			}
			if(menu.subMenuId === submenu.subMenuId) {
				submenu.openCount = submenu.active ? self.props.sideMenuStore.selectedMenu.menuOpenCount : 0;
			}
		});
	}

	closeOtherMenu(menu) {
		if(this.props.sideMenuStore.selectedMenu.menuOpenCount > 0) {
			this.props.submenuData.map(function(submenu, menuIndex){
				if(submenu.subMenuId !== menu.subMenuId && menu.active) {
					submenu.active = false;
					submenu.openCount = 0;
				}
			});
			this.props.sideMenuStore.selectedMenu.menuOpenCount = 1;
		}
	}

	LightenDarkenColor(col, amt) {
  
	    var usePound = false;
	  
	    if (col[0] == "#") {
	        col = col.slice(1);
	        usePound = true;
	    }
	 
	    var num = parseInt(col,16);
	 
	    var r = (num >> 16) + amt;
	 
	    if (r > 255) r = 255;
	    else if  (r < 0) r = 0;
	 
	    var b = ((num >> 8) & 0x00FF) + amt;
	 
	    if (b > 255) b = 255;
	    else if  (b < 0) b = 0;
	 
	    var g = (num & 0x0000FF) + amt;
	 
	    if (g > 255) g = 255;
	    else if (g < 0) g = 0;
	 
	    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
	  
	}

	render(){
		var self = this;
		const {submenuData,
			   appstore,
			   sideMenuStore} = this.props;
		let isVaEnabled = appstore.userInfo.accountplan.canUseVirtualAgent;
		let iscannedEnabled = appstore.userInfo.accountplan.canned_response;
		let isreportEnabled = appstore.userInfo.accountplan.reports;
		let isTranferEnabled = appstore.userInfo.accountplan.transfer_file; 
		let isChatbotEnabled = appstore.userInfo.accountplan.enable_chatbot;
		const vacss = {
			pointerEvents: "none",
			opacity: 0.3,
		    };
		const nocss = {};	
	
		//	   (appstore.userInfo.accountsmodel.canUseVirtualAgent == 1 && submenu.subMenuLabel === 'Virtual Agent')	   
		var subMenuList = submenuData.map(function(submenu,index){
			
			return(
				// key={index}
				
				<span key={"submenuDatas"+index} data-tooltip={((isreportEnabled!=1 && submenu.subMenuLabel === "reports") || (iscannedEnabled!=1 && submenu.subMenuLabel === "Responses") || (isVaEnabled != 1 && submenu.subMenuLabel === "Virtual Agent")  || (isChatbotEnabled != 1 && submenu.subMenuLabel === "ChatBot")  ) ? appstore.getLanguageTrans("Upgrade your plan") :""} data-tooltip-conf="top">
				<li key={"submenuData"+index} className={(submenu.menuList.length === 0 && submenu.active && (sideMenuStore.selectedMenu.menuId === 1 && submenu.subMenuId !== 4 )) ? "parent_list active" : "parent_list"} title={((isreportEnabled!=1 && submenu.subMenuLabel === "reports") || (iscannedEnabled!=1 && submenu.subMenuLabel === "Responses") || (isVaEnabled != 1 && submenu.subMenuLabel === "Virtual Agent") || (isChatbotEnabled != 1 && submenu.subMenuLabel === "ChatBot") ) ? appstore.getLanguageTrans("Please upgrade your plan"):""}  style={((isreportEnabled!=1 && submenu.subMenuLabel === "reports") || (iscannedEnabled!=1 && submenu.subMenuLabel === "Responses") || (isVaEnabled != 1 && submenu.subMenuLabel === "Virtual Agent") || (isChatbotEnabled != 1 && submenu.subMenuLabel === "ChatBot") ) ? vacss : nocss}>
					<a href={"#"+submenu.subMenuLabel.replace(/\s/g,'')} data-toggle="collapse" aria-expanded="false" 
						className={(submenu.menuList.length !== 0 || (sideMenuStore.selectedMenu.menuId === 1 && submenu.subMenuId === 4 ) && submenu.active )  ? "dropdown-toggle" : "dropdown-toggle collapsed" } onClick={() => self.getDefaultSelectedItem(submenu)}>
						<p className="text-uppercase" onClick={() => submenu.menuList.length === 0 ? sideMenuStore.navigate(submenu, appstore) : null}>{submenu.subMenuLabel !== 'Online Operators' ? appstore.getLanguageTrans(submenu.subMenuLabel) : ''}
							{
								submenu.subMenuLabel === 'Online Operators' &&
								<React.Fragment>
									<span className="online_text">{appstore.getLanguageTrans("Online")}</span><br/> {appstore.getLanguageTrans("Operators")}
								</React.Fragment>
							}
						</p>
					</a>
					{
						(submenu.menuList.length !==0 && sideMenuStore.selectedMenu.menuId !== 1) &&
							<ul className={submenu.active ? "collapse list-unstyled show list_menu" : "collapse list_menu list-unstyled"} id={submenu.subMenuLabel.replace(/\s/g,'')}>
			                	{
			                		submenu.menuList.map(function(list,listIndex){
			                			return(
											<React.Fragment key={Math.random()}>
												<span  data-tooltip={(isTranferEnabled != 1 && list.listLabel === "file transfer")? appstore.getLanguageTrans("Upgrade Your Plan"):""} data-tooltip-conf={(list.listLabel === "file transfer")?"top":""}>
			                				<li key={Math.random()} className={(sideMenuStore.submenuSelectedItem === list.listLabel ? 'cursor-pointer active ' : 'cursor-pointer ')} id={list.listLabel+"Tour" } style={(isTranferEnabled !=1 && list.listLabel === "file transfer")?vacss:nocss} 
			                					onClick={() => {self.closeOtherMenu(submenu);sideMenuStore.submenuSelectedItem = list.listLabel;sideMenuStore.navigate(list, appstore)}}>
			                				
			                					
												<p className="text-capitalize"  style={{color: "#f7f5f5" }} >{appstore.getLanguageTrans(list.listLabel)}</p>
												{/* style={{color: appstore.selectedColor.color+"e8" }} */}
											</li>
											</span>
											</React.Fragment>
			                			)
			                		})
			                	}
			                </ul>
					}
					{
						(sideMenuStore.selectedMenu.menuId === 1 && submenu.subMenuId === 4 ) &&
							<ul className={submenu.active ? "collapse list-unstyled show list_menu submenu_operator" : "collapse list_menu list-unstyled submenu_operator"} id={submenu.subMenuLabel.replace(/\s/g,'')}>
								<OnlineOperatorsComponent 
								hideHeader={true}
								appstore={appstore}
								/>
							</ul>
					}
	                
				</li>
				</span>
			)
				}
		)
		
		return (
				<div id={appstore.selectedWebisiteUid} className={!appstore.menuActive ? "submenu_wrapper active" : "submenu_wrapper"} style={{pointerEvents:(appstore.userInfo.allwebistes.length ==0 || appstore.helpTourStarted) ?'none': 'auto',opacity:appstore.userInfo.allwebistes.length ==0 ? 0.5:1}}>
					<button type="button" className="menuClose close d-lg-none" aria-label="Close" onClick={() => appstore.closeSubmenu()}>
			  			<span aria-hidden="true">&times;</span>
					</button>
					{
						(sideMenuStore.selectedMenu.menuId !== 2  && sideMenuStore.selectedMenu.menuId !== 1) && 
							<div className="logoIcon customDrpDwn customelogoIcon" >
							 { appstore.userInfo.allwebistes.length == 0 &&
									<p className="icon_wrapper"><img src={imageURLs.logo} style={{borderRadius: "37px"}}  alt="logo" className="img-fluid" /><p></p></p>
	                         }
						  	{  appstore.userInfo.allwebistes.length != 0 &&
								<DropDownComponent dropDownOptions={sideMenuStore.logoDrpDown}
																		key={Math.random()}
											    						store={sideMenuStore}
													    				callBack={(selectedOption) => sideMenuStore.selectSite(selectedOption)}
													    				selectedOption = {sideMenuStore.selectedSite}/>
							}
							</div>
					}
					{
						sideMenuStore.selectedMenu.menuId === 1 &&
							<CurrentChatsComponent 
							appstore={appstore}
							/>
					}
					{
						sideMenuStore.selectedMenu.menuId !== 2 && 
					
					<ul id={sideMenuStore.selectedMenu.menuId === 4 ? "admin_page":"operator_page"} className={!appstore.menuActive ? "subMenu active list-unstyled components" : "subMenu list-unstyled components"}>
						{subMenuList}
						
					</ul>
					}

					{
						sideMenuStore.selectedMenu.menuId === 2 &&
							<OnlineOperatorsComponent 
							appstore={appstore}
							/>
					}
				</div>
				
		)
	}
})
export default SideSubMenuComponent;
