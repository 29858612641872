import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';

import imageURLs from '../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../sharedComponents/dropDown/dropDown';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';
import WarningComponent from '../../../sharedComponents/confirmationPopup/WarningComponent';
//import FormValidator from '../../../sharedFiles/FormValidator';
// import 'react-image-crop/dist/ReactCrop.css';
// import '../../../library/custom-image-crop.css';
import ImageCropComponent from '../../../sharedComponents/imageCropPopUp/ImageCropComponent';
import ImageWarningPopUp from '../../../sharedComponents/imageCropPopUp/ImageWarningPopUp';

import ChatBotSettingsStore from './ChatBotSettingsStore';

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const imageMaxSize = 10000000 // bytes
const acceptedFileTypes = 'image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })
const ChatBotSettingsComponent = observer(class ChatBotSettingsComponent extends Component {

	constructor(props, context) {
		super(props, context);
		this.editor = "";
		this.chatBotSettingsStore = new ChatBotSettingsStore(this.props.appstore);
		this.fileInputRef = React.createRef();
		this.state = {			
			websiteUid: this.props.appstore.selectedWebisiteUid,
			uploading: false,
			images: "",
			actual_file: "",
			nameError: ""
		}
		this.submitted = false;	
	}

	componentDidMount() {
	//	this.props.appstore.menuChanged = window.location.pathname;		
		this.chatBotSettingsStore.getsettingsdata();		
	}
	componentWillUnmount() {
		window.scrollTo(0, 0);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.websiteUid !== this.props.appstore.selectedWebisiteUid) {			
			 if(!this.props.appstore.componentDidUpdate){
				this.chatBotSettingsStore.getsettingsdata(); 
				this.props.appstore.componentDidUpdate=true;
			} 			
			this.chatBotSettingsStore.cropimage.croppedImageUrl = null;
			this.setState({ websiteUid: this.props.appstore.selectedWebisiteUid });
		}
	}
	handleChange = (event) => {
		this.updateProperty(event.target.name, event.target.value, event.target.id, event);
		this.setState({ nameError: "" });
	}

	updateProperty(key, value, id, e) {
		let self = this;
		this.chatBotSettingsStore.agentDetails[key] = value;
		// }
	}
	selectComponent = () => {
		this.chatBotSettingsStore.deleteImg = true;
		// console.log(this.settingsStore.deleteImg);
	} 

	validateForm = () => {
		let isValid = true;
	
		if (!this.chatBotSettingsStore.agentDetails.name || this.chatBotSettingsStore.agentDetails.name.trim() === '') {
			this.setState({ nameError: 'Name of ChatBot is required.' });
			isValid = false;
		} else {
			this.setState({ nameError: '' });
		}
	
		return isValid;
	}	

	validatesubmit() {
		
		this.submitted = true;			
		if (this.validateForm()) {
			const formData = new FormData()
			formData.set('id', '100');
			if (this.chatBotSettingsStore.agentDetails.file != '' && this.chatBotSettingsStore.agentDetails.file != undefined) {
				// this.settingsStore.agentDetails.file.forEach((file, i) => {
				this.state.images = this.chatBotSettingsStore.agentDetails.file;
				this.state.actual_file = this.chatBotSettingsStore.agentDetails.selectedfile[0];

				// })
				this.setState({ uploading: true })
				// this.settingsStore.agentDetails.file.forEach((file, i) => {
				formData.append("file", this.state.images)
				formData.append("actualfile", this.state.actual_file)
				formData.append("fileMimeType", this.state.fileMimeType);
				// })
			}
			this.chatBotSettingsStore.savesettings(formData);
		}
		else {
			window.scrollTo(0, 450);
		}
	}

	//-- Croping Images --//
	verifyFile = (files) => {
		let self=this;
		if (files && files.length > 0) {
			const currentFile = files[0]
			// console.log(currentFile);
			const currentFileType = currentFile.type
			this.setState({ imagetype: currentFileType })
			const currentFileSize = currentFile.size
			if (currentFileSize > imageMaxSize) {
				this.chatBotSettingsStore.warning_image_name = this.props.appstore.getLanguageTrans("Oops, the file is too large. The file size should be less than 10Mb.");
				this.chatBotSettingsStore.imagewarning = true;
				// alert("This file is not allowed. " + currentFileSize + " bytes is too large")
				return false
			}
			if (!acceptedFileTypesArray.includes(currentFileType)) {
				this.chatBotSettingsStore.warning_image_name = currentFile.name + ": "+this.props.appstore.getLanguageTrans("An internal server error occurred.");
				this.chatBotSettingsStore.imagewarning = true;
				// alert("This file is not allowed. Only images are allowed.")
				return false
			}
			return true
		}
	}

	onSelectFile = e => {
		this.chatBotSettingsStore.agentDetails.selectedfile = e.target.files;
		const files = e.target.files
		if (files && files.length > 0) {
			const isVerified = this.verifyFile(files)
			if (isVerified) {
				const reader = new FileReader();
				reader.addEventListener('load', () =>
					// this.setState({ src: reader.result })
					this.chatBotSettingsStore.cropimage.src = reader.result
				);
				reader.readAsDataURL(e.target.files[0]);
				this.chatBotSettingsStore.imageCropModel = true;
			}
		}
	};
	onCropComplete = crop => {
		this.chatBotSettingsStore.imageCropModel = false
		this.makeClientCrop(crop);
	};

	async makeClientCrop(crop) { 
		if (this.chatBotSettingsStore.imageRef && crop.width && crop.height) {
			const imagetypes = this.state.imagetype == 'image/png' ? 'newFile.png'
				: this.state.imagetype == 'image/jpg' ? 'newFile.jpg' : this.state.imagetype == 'image/jpeg' ? 'newFile.jpg'
					: 'newFile.gif';
			const mimetype = this.state.imagetype == 'image/png' ? 'image/png'
				: this.state.imagetype == 'image/jpg' ? 'image/jpg' : this.state.imagetype == 'image/jpeg' ? 'image/jpg'
					: 'image/gif';
			this.state.fileMimeType = mimetype;
			const croppedImageUrl = await this.getCroppedImg(
				this.chatBotSettingsStore.imageRef,
				crop,
				imagetypes,
				mimetype
			);
			// this.setState({ croppedImageUrl }); 
			this.chatBotSettingsStore.cropimage.croppedImageUrl = croppedImageUrl;

		}
	}

	handleClearToDefault = event => {
		this.chatBotSettingsStore.imageCropModel = false;
		this.chatBotSettingsStore.agentDetails.selectedfile = "";
		this.chatBotSettingsStore.agentDetails.file="";
		this.state.actual_file="";
		this.state.fileMimeType="";
		if (event) event.preventDefault()
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height)

		this.chatBotSettingsStore.cropimage = {
			src: null,
			crop: {
				unit: '%',
				width: 50,
				aspect: 1 / 1,
			},
			croppedImageUrl: null,
		}
		this.fileInputRef.current.value = null
	}

	getCroppedImg(image, crop, fileName,mimetype) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);
		const imageData64 = canvas.toDataURL('image/' + this.chatBotSettingsStore.cropimage)
		const myFilename = fileName
		// file to be uploaded
		const myNewCroppedFile = this.props.appstore.base64StringtoFile(imageData64, myFilename)
		this.chatBotSettingsStore.agentDetails.file = myNewCroppedFile
		// download file
		// this.props.appstore.downloadBase64File(imageData64, myFilename)
		// this.handleClearToDefault()

		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = window.URL.createObjectURL(blob);
				resolve(this.fileUrl);
			}, mimetype);
		});
	}

	render() {
		const { crop, croppedImageUrl, src } = this.chatBotSettingsStore.cropimage;

		const { appstore } = this.props;
		if (this.chatBotSettingsStore.pagenotLoaded) {
			return (<div><div className="adminloader">
				<GridLoader
					css={override}
					size={20}
					marging={2}
					color={this.props.appstore.selectedColor.color}
				/>
			</div> </div>)
		}
		return (

			<div key={appstore.selectedWebisiteUid} className="settings_wrapper w-100">
				<div className="header_title row align-items-center m-0">
					<img src={imageURLs.settingsIcon} alt="faq" className="img-fluid" />
					<h4 className="text-left">{appstore.getLanguageTrans("Settings")}</h4>
				</div>
				<div className="row">
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box">
							<Form className="customInputForm">
								<Form.Group as={Row} controlId="formGroupLanguage">
									<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Enable mode")}</Form.Label>
									<Col sm="8" lg="9" className="customDrpDwn">
										<DropDownComponent dropDownOptions={this.chatBotSettingsStore.dropDownOptions}
											callBack={(selectedOption) => this.chatBotSettingsStore.mode = selectedOption}
											selectedOption={this.chatBotSettingsStore.mode}
										/>
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formGroupAgent">
									<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Image")}</Form.Label>
									<Col>
										<div className="image_upload">
											<Button variant="light" className="cursor-pointer">
												<p>{appstore.getLanguageTrans("Upload & edit")}</p>
												<input ref={this.fileInputRef} multiple={false} type="file" accept={acceptedFileTypes} id="file" onChange={this.onSelectFile} />
												{/* <input type="file" id="file" onChange={(e) => this.handleChange(e)} /> */}
											</Button>
											<div className="profileImg row align-items-center w-100 h-100 m-0">
												{this.chatBotSettingsStore.cropimage.croppedImageUrl && (
													<React.Fragment>
													<img alt="Crop" className="img-fluid" style={{ width: "100px", height: "100px", borderRadius: "50px" }} src={croppedImageUrl} />
													<div className="img_delete pointerhand" onClick={() => this.handleClearToDefault()}><p className="rotate">+</p><p>{appstore.getLanguageTrans("Delete current image")}</p></div>
													</React.Fragment>
												)}												
												{(!this.chatBotSettingsStore.cropimage.croppedImageUrl && (this.chatBotSettingsStore.agentDetails.image != '' && this.chatBotSettingsStore.agentDetails.image != undefined)
													&& this.chatBotSettingsStore.deleteImg == false) && (
														<React.Fragment>
															<img src={this.props.appstore.resourceurl + this.props.appstore.userInfo.accountsmodel.account_uid + "/" + this.chatBotSettingsStore.agentDetails.image} style={{ width: "100px", height: "100px", borderRadius: "50px" }} alt="avatar" className="img-fluid" />
															<div className="img_delete pointerhand" onClick={() => this.selectComponent()}><p className="rotate">+</p><p>{appstore.getLanguageTrans("Delete current image")}</p></div>
														</React.Fragment>
												)}
											</div>										
										</div>
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formGroupNameBefore">
									<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Skip pre-chat fields")}</Form.Label>
									<Col className="custom_radioBtn">
										<CheckBoxComponent radioValue={this.chatBotSettingsStore.prechat}
											callBack={() => this.chatBotSettingsStore.prechat = !this.chatBotSettingsStore.prechat} />
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formGroupNameAfter">
									<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Show Chat with a real person link during AI ChatBot chat if there's an available operator.")}</Form.Label>
									<Col className="custom_radioBtn">
										<CheckBoxComponent radioValue={this.chatBotSettingsStore.realchat}
											callBack={() => this.chatBotSettingsStore.realchat = !this.chatBotSettingsStore.realchat} />
									</Col>
								</Form.Group>								
								<Form.Group as={Row} controlId="formGroupAgent">
									<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Name of ChatBot")}</Form.Label>
									<Col sm="8" lg="9">																			
										<Form.Control
											type="text"
											className={this.state.nameError ? 'has-error' : ''}
											name="name"
											value={this.chatBotSettingsStore.agentDetails.name}
											onChange={(e) => this.handleChange(e)}
											placeholder={appstore.getLanguageTrans("Enter ChatBot Name")}
											required
										/>
									{/* {this.state.nameError && <span className="errorText">{this.state.nameError}</span>} */}
									</Col>
								</Form.Group>
								<div className="row m-0">
									<div className="col-12 formBtns">
										<div className="pullRight">
											<Button className="btn_clr" variant="light" onClick={() => this.chatBotSettingsStore.cancelsettings()} >
												<p>{appstore.getLanguageTrans("Cancel")}</p>
											</Button>
											<Button className="btn_save" onClick={() => this.validatesubmit()}>
												<p>{appstore.getLanguageTrans("Save")}</p>
											</Button>
										</div>
									</div>
								</div>
							</Form>
							{this.chatBotSettingsStore.errormodel &&
								<WarningComponent show={this.chatBotSettingsStore.errormodel}
									store={this.chatBotSettingsStore}
									cancel_btn="OK"
									closePopup={() => this.chatBotSettingsStore.errormodel = false}
									appStore={appstore} />
							}
							{this.chatBotSettingsStore.imagewarning &&
								<ImageWarningPopUp show={this.chatBotSettingsStore.imagewarning}
									store={this.chatBotSettingsStore}
									cancel_btn="Close"
									closePopup={() => this.chatBotSettingsStore.imagewarning = false}
									appStore={appstore} />
							}
							{this.chatBotSettingsStore.imageCropModel &&
								<ImageCropComponent show={this.chatBotSettingsStore.imageCropModel}
									store={this.chatBotSettingsStore}
									cancel_btn="Clear"
									multi="0"
									crop_btn="Save"
									onCropComplete={() => this.onCropComplete(crop)}
									closePopup={() => this.handleClearToDefault()}
									appStore={appstore} />
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
});
export default ChatBotSettingsComponent;
