import { decorate, observable, action } from 'mobx';
import axios from 'axios';
import imageURLs from '../../../sharedFiles/ImagesURLs';

class ScrapWebsiteStore {
  constructor() {
    this.websitesUrl = {
      urlname: "",
      newWebsite: "",
    };
    this.validationError = "";
    this.showCards = false;
    this.showModal = false;
    this.submittedWebsites = [];
    this.checkboxStates = {};
    this.loading = false;
    this.selectedMainUrl = "";
    this.subPages = [];
    this.mainPagesWithCount = {};
    this.mainAndSubUrls = {}; // Initialize as empty object
    this.subCheckboxStates = {};
    this.urlToFilePathMap = {};
    this.totalTokenCount = 0;
    this.continueDisabled = false;
    this.tooltipMessage = "";
    this.urlStatusMap = {};
    this.totalpagecount = 0;
    this.ai_chatbotuid = "";
    this.websitesdetails = [];
    this.websiteurlloading = false;
    this.showConfirmationModal = false;
    this.validationurlcount = 0;
    this.website_pagename = [];
    this.ispageLoaded = false;
    this.subUrls = [];
    this.mainurlname = "";
    this.ai_chatbot_id=0;   
    this.disablechatbot=false;
    this.enablechatbot=false; 
    this.tokenexcced_msg = "";
    this.confirmembeddedstatus=false;
    this.cancelbotstatus=false;
    this.processingpageloadded = false;
    this.hasSuccessEmbed = false;
  }

  setWebsitesUrl(value) {
    this.websitesUrl.urlname = value;
  }

  resetForm() {
    this.websitesUrl.urlname = '';
    this.validationError = "";
    this.showCards = false;
    this.showModal = false;
    this.submittedWebsites = [];
    this.checkboxStates = {};
    this.subCheckboxStates = {};
    this.mainAndSubUrls = {};
  }

  updateState(data) {
    Object.assign(this, data);
  }

  // // Function to handle checkbox change for both main and sub URLs
  // handleCheckboxChange(url, isMainUrl = true) {
  //   console.log("handleCheckboxChange", url, isMainUrl);
  //   const { checkboxStates, subCheckboxStates, mainAndSubUrls } = this;

  //   let totalTokenCount = this.totalTokenCount;
  //   // Function to update token count based on tokensize and add/remove state
  //   const updateTokenCount = (tokensize, add) => {
  //     return add ? totalTokenCount + tokensize : totalTokenCount - tokensize;
  //   };

  //   if (isMainUrl) {
  //     // Toggle the state of main URL checkbox
  //     const isChecked = !checkboxStates[url];
  //     checkboxStates[url] = isChecked;

  //     // Update sub URL checkboxes if main URL is toggled
  //     if (mainAndSubUrls[url]) {
  //       mainAndSubUrls[url].forEach(subPage => {
  //         const { url: subUrl, tokensize } = subPage;
  //         subCheckboxStates[subUrl] = isChecked;
  //         totalTokenCount = updateTokenCount(tokensize, isChecked);
  //       });
  //     }
  //   } else {
  //     //console.log("*** "+subCheckboxStates[url] = !subCheckboxStates[url]);
  //     // Toggle the state of sub URL checkbox
  //     subCheckboxStates[url] = !subCheckboxStates[url];
  //     // Check if all sub URLs for the main URL are checked or not
  //     const mainUrl = Object.keys(mainAndSubUrls).find(key => mainAndSubUrls[key].some(subPage => subPage.url === url));
  //     console.log("mainUrl", mainUrl,JSON.stringify(subCheckboxStates));
  //     if (mainUrl) {
  //       const allSubUrlsChecked = mainAndSubUrls[mainUrl].every(subPage => subCheckboxStates[subPage.url]);
  //       checkboxStates[mainUrl] = allSubUrlsChecked;
  //     }

  //     // Update total token count based on sub URL state change
  //     const subPage = mainAndSubUrls[mainUrl].find(subPage => subPage.url === url);
  //     if (subPage) {
  //       const { tokensize } = subPage;
  //       totalTokenCount = updateTokenCount(tokensize, subCheckboxStates[url]);
  //     }
  //   }

  handleCheckboxChange(url, isMainUrl = true) {    
    const { checkboxStates, subCheckboxStates, mainAndSubUrls } = this;
  
    let totalTokenCount = this.totalTokenCount;
  
    // Function to update token count based on tokensize and add/remove state
    const updateTokenCount = (tokensize, add) => {
      return add ? totalTokenCount + tokensize : totalTokenCount - tokensize;
    };
  
    if (isMainUrl) {
      // Toggle main URL checkbox state
      const isChecked = !checkboxStates[url]; // If checked before, now it will be unchecked
      checkboxStates[url] = isChecked;
  
      // Update all sub-URLs when main is clicked
      if (mainAndSubUrls[url]) {
        mainAndSubUrls[url].forEach(subPage => {
          const { url: subUrl, tokensize } = subPage;
          subCheckboxStates[subUrl] = isChecked; // Set all sub-URLs same as main
          totalTokenCount = updateTokenCount(tokensize, isChecked);
        });
      }
    } else {
      // Toggle sub URL checkbox state
      subCheckboxStates[url] = !subCheckboxStates[url];
  
      // Find main URL linked to this sub URL
      const mainUrl = Object.keys(mainAndSubUrls).find(key =>
        mainAndSubUrls[key].some(subPage => subPage.url === url)
      );
  
      if (mainUrl) {
        // If at least one sub-URL is checked, enable the main URL
        const anySubChecked = mainAndSubUrls[mainUrl].some(subPage => subCheckboxStates[subPage.url]);
        checkboxStates[mainUrl] = anySubChecked; // Enable main if any sub is enabled
      }
  
      // Update total token count based on sub URL state change
      const subPage = mainAndSubUrls[mainUrl].find(subPage => subPage.url === url);
      if (subPage) {
        const { tokensize } = subPage;
        totalTokenCount = updateTokenCount(tokensize, subCheckboxStates[url]);
      }
    }

    // Update total token count and tooltip message based on token limit
    const continueDisabled = 0 > 10000;    
    const tooltipMessage = continueDisabled ? "Your token limit exceededssss" : "";
    const tokenexcced_msg = continueDisabled ? "You have reached the limit of 10,000 words per scan as per your plan. Please unselect a few pages to proceed or consider upgrading your plan for higher limits." : "";
    this.updateState({
      checkboxStates: { ...checkboxStates },
      subCheckboxStates: { ...subCheckboxStates },
      totalTokenCount,
      continueDisabled,
      tooltipMessage,
      tokenexcced_msg,
    });
  }

  // Fetch all websites URLs based on selectedWebisiteUid
  async fetchWebsites(appstore) {
    this.websiteurlloading = true;
    try {
      const result = await axios({
        method: 'GET',
        url: `${appstore.url}getAllWebsitesURL.json`,
        headers: { 'Content-Type': 'application/json' },
        params: { website_uid: appstore.selectedWebisiteUid },
      });

      if (result.data != null) {
        this.updateState({ websitesdetails: result.data,ispageLoaded: true });
      }
    } catch (error) {
      console.error('Error fetching websites:', error);
    } finally {
      this.updateState({ websiteurlloading: false });
    }
  }

  async handleRefresh(appstore) {
    
    await this.fetchWebsites(appstore);
  
    const hasSuccessEmbed = this.websitesdetails.some(
      (site) => site.embed_status === "Success"
    );
     // Set loading state
     this.processingpageloadded = true;
     this.hasSuccessEmbed = hasSuccessEmbed     
      // Update appstore to trigger re-render
      appstore.setProcessingComplete(hasSuccessEmbed);
    // If success, navigate to ChatbotHomeComponent
    if (hasSuccessEmbed) {     
      appstore.navigate("/admin/ChatBot/ChatbotHomeComponent");
    }
  };

  refreshimagesetonhover = ()=>{
      document.getElementById("archiveimg").src=imageURLs.refresh_white; 
  }
  
  refreshimagesetonhoverout = (color)=>{
      let imageurl= "#3ba8c5" == color ? imageURLs.refresh_steelblue : 
                    "#4cb6ac" == color ? imageURLs.refresh_seagreen :
                    "#ec407a" == color ?  imageURLs.refresh_pink :
                    "#6e4c42" == color ?imageURLs.refresh_brown :
                    "#234e91" == color ?imageURLs.refresh_navyblue :
                    "#e89712" == color ?imageURLs.refresh_yellow :
                    "#b968c7" ==color ?imageURLs.refresh_voilet : 
                    "#039be6" == color ? imageURLs.refresh_blue :
                    "#00887a" == color ? imageURLs.refresh_darkgreen :
                    "#4b8f4e" == color ? imageURLs.refresh_green :
                    "#5f7c8c" == color ? imageURLs.refresh_grey :
                    "#64a9e0"== color ? imageURLs.refresh_skyblue : imageURLs.refresh_steelblue;
      document.getElementById("archiveimg").src=imageurl;        
  }

  // Validate if website URL exists based on website_uid and websiteurl_name
  async validateurlExist(websiteurlname, appstore) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${appstore.url}getvalidateurl.json`,
        headers: { 'Content-Type': 'application/json' },
        params: { website_uid: appstore.selectedWebisiteUid, websiteurl_name: websiteurlname },
      });

      if (result.data != null) {
        this.updateState({ validationurlcount: result.data });
      }
    } catch (error) {
      console.error('Error fetching websites:', error);
    }
  }

  // Fetch list of website main page names based on ai_chatbotuid
  async getWebsitePageNames(appstore) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${appstore.url}getListOfWebsiteMainpageName.json`,
        headers: { 'Content-Type': 'application/json' },
        params: { ai_chatbotuid: appstore.selectedWebisiteUid },
      });

      if (result.data != null) {        
        this.updateState({ website_pagename: result.data, ispageLoaded: true });
      }
    } catch (error) {
      console.error('Error fetching websitePageNames :', error);
    }
  }
  disablechatbotAction(chatbotval){      
      this.disablechatbot=true;
      this.chatbotdisablevalue = chatbotval;   
  }
  enablechatbotAction(chatbotval){      
    this.enablechatbot=true;
    this.chatbotdisablevalue = chatbotval
}

  confirmdisableOREnablechatbot=(appstore,status) =>{
    axios({
      method: 'POST',
      url: appstore.url+'disable_enable_ChatBot.json',
      headers: { 'content-type': 'application/json' },
      data: {
        ...this.chatbotdisablevalue, 
        type: status
      }
      }).then(result=> { 
        if(result.data.length==1){
          if(result.data[0].logoutstatus==1){
            appstore.logout();
          }else{
            this.websitesdetails=result.data;
          //  this.props.appstore.userInfo.accountsmodel.chatbotusedcount = result.data.chatbotUsedcnt          
          //  this.props.appstore.userInfo.accountsmodel.enabled_chatbotcount = result.data.enabledChatbotCount
          }
        }
      appstore.global_errorcount=0;
       
     }).catch((error) => {        
        console.log(error);
        console.log("error count :"+appstore.global_errorcount);
        appstore.global_errorcount=appstore.global_errorcount+1;
        if(appstore.global_errorcount>20){
        // appstore.logout();
        }
    })
    if(status=='disable')
      this.disablechatbot=false;
    else
      this.enablechatbot=false;
  } 
}

decorate(ScrapWebsiteStore, {
  websitesUrl: observable,
  validationError: observable,
  showCards: observable,
  showModal: observable,
  submittedWebsites: observable,
  checkboxStates: observable,
  loading: observable,
  selectedMainUrl: observable,
  subPages: observable,
  mainPagesWithCount: observable,
  mainAndSubUrls: observable,
  subCheckboxStates: observable,
  urlToFilePathMap: observable,
  totalTokenCount: observable,
  continueDisabled: observable,
  tooltipMessage: observable,
  urlStatusMap: observable,
  totalpagecount: observable,
  ai_chatbotuid: observable,
  websitesdetails: observable,
  websiteurlloading: observable,
  showConfirmationModal: observable,
  validationurlcount: observable,
  website_pagename: observable,
  ispageLoaded: observable,
  subUrls: observable,
  mainurlname: observable,
  setWebsitesUrl: action,
  resetForm: action,
  updateState: action,
  handleCheckboxChange: action,
  handleMainCheckboxChange: action,
  handleSubCheckboxChange: action,
  fetchWebsites: action,
  validateurlExist: action,
  getWebsitePageNames: action,
  fetchSubUrls: action,
  ai_chatbot_id : observable,
  disablechatbot : observable,
  chatbotdisablevalue : observable,
  enablechatbot : observable,
  tokenexcced_msg : observable,
  confirmembeddedstatus : observable,
  cancelbotstatus: observable,
  processingpageloadded: observable,
  handleRefresh: action,
  hasSuccessEmbed : observable
});

export default ScrapWebsiteStore;
