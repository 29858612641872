import React, { Component } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import { observer } from 'mobx-react';

import navigationStore from '../sharedFiles/navigation-store';

import HomeComponent from '../components/homeComponent/HomeComponent';

import SettingsComponent from '../components/admin/settings/Settings';
import OverviewComponent from '../components/admin/overview/Overview';
import ScriptComponent from '../components/admin/script/Script';
import InvitationRulesComponent from '../components/admin/invitationRules/InvitationRules';

import SecurityComponent from '../components/admin/setup/security/SecurityComponent';
import LanguageTextComponent from '../components/admin/setup/languages_texts/LanguageTextComponent';
import FileTransferComponent from '../components/admin/setup/file_transfer/FileTransferComponent';
import ChatInvitationComponent from '../components/admin/setup/chat_invitations/ChatInvitationComponent';
import AddSettingsComponent from '../components/admin/setup/add_settings/AddSettingsComponent';
import OperatorsDeptComponent from '../components/admin/setup/operators_dept/OperatorsDept';
import WidgetComponent from '../components/admin/setup/widgets/WidgetComponent';
import WebsiteWidgetComponent from '../components/websiteWidget/WidgetComponent';
import ReportPerformanceComponent from '../components/admin/reports/ReportPerformanceComponent';
import ReportCharts from '../components/admin/reports/reportcharts/ReportCharts';
import DailyOverViewComponent from'../components/admin/reports/dailyOverView/DailyOverViewComponent';
import ReportUsage from '../components/admin/reports/reportsUsage/ReportUsage';
import ScrapWebsiteComponent from '../components/admin/ChatBot/ScrapWebsiteComponent';
import ChatbotHomeComponent from '../components/admin/ChatBot/chatbotHomeComponent';
import OperatorReports from '../components/admin/reports/operatorReports/OperatorReports';
import EditChatbotAIComponent from '../components/admin/ChatBot/editChatbotAIComponent';
import ChatBotSettingsComponent from '../components/admin/ChatBot/ChatBotSettingsComponent';
import ChatBotProcessingComponent from '../components/admin/ChatBot/chatBotProcessingComponent';

const AdminPageLayout = observer(class AdminPageLayout extends Component {eportUsage

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }


  componentWillReceiveProps(nextProps){
  }

  /**
   * @param route
   */
  render() {

    const { appstore } = this.props;
    return (    
      <Router history={navigationStore.history}>
        <Switch>
          <Route exact path="/admin/virtual_agent" render={()=><OverviewComponent 
                         appstore={appstore} />}/>
          <Route exact path="/admin/virtual_agent/overview" render={()=><OverviewComponent 
                         appstore={appstore} />}/>
          <Route exact path="/admin/virtual_agent/script" render={()=><ScriptComponent 
                         appstore={appstore} />}/>
          <Route exact path="/admin/virtual_agent/settings" render={()=><SettingsComponent 
                         appstore={appstore} />}/>
         <Route exact path="/admin/virtual_agent/invitation_rules" render={()=><InvitationRulesComponent 
                         appstore={appstore} />}/>

         <Route exact path="/admin/setup/security" render={()=><SecurityComponent 
                         appstore={appstore} />}/>

         <Route exact path="/admin/setup/language_text" render={()=><LanguageTextComponent 
                         appstore={appstore} />}/>

          <Route exact path="/admin/setup/file_transfer" render={()=><FileTransferComponent 
                         appstore={appstore} />}/>

          <Route exact path="/admin/reports/reportPerformance" render={()=><ReportPerformanceComponent 
                         appstore={appstore} />}/>

          <Route exact path="/admin/reports/reportcharts/ReportCharts" render={()=><ReportCharts 
                         appstore={appstore} />}/>

          <Route exact path="/admin/reports/operatorReports/OperatorReports" render={()=><OperatorReports 
                         appstore={appstore} />}/>

          <Route exact path="/admin/reports/reportUsage/ReportUsage" render={()=><ReportUsage 
                         appstore={appstore} />}/>

          <Route exact path="/admin/reports/dailyOverView/DailyOverViewComponent" render={()=><DailyOverViewComponent 
                         appstore={appstore} />}/>

          <Route exact path="/admin/setup/chat_invitations" render={()=><ChatInvitationComponent 
                         appstore={appstore} />}/>

          <Route exact path="/admin/setup/settings" render={()=><AddSettingsComponent 
                         appstore={appstore} />}/>

          <Route exact path="/admin/setup/operators_departments" render={()=><OperatorsDeptComponent 
                         appstore={appstore} />}/>

          <Route exact path="/admin/setup/widgets/add" render={()=><WidgetComponent 
                         appstore={appstore}
                         menuActive = {appstore.menuActive} />}/>
          <Route exact path="/admin/setup/widgets/:websiteId" render={()=><WebsiteWidgetComponent 
                         appstore={appstore}
                         menuActive = {appstore.menuActive} />}/>
          <Route exact path="/admin/setup/widgets" render={()=><WebsiteWidgetComponent 
                         appstore={appstore}
                         menuActive = {appstore.menuActive} />}/>
          <Route exact path="/admin" render={()=><HomeComponent 
                         appstore={appstore} menuActive = {appstore.menuActive} />}/>

          <Route exact path="/admin/ChatBot/ChatbotHomeComponent" render={() =>
          <ChatbotHomeComponent appstore={appstore} />}/>
            
          <Route exact path="/admin/ChatBot" render={() =>
          <ScrapWebsiteComponent appstore={appstore}  />}/>

          <Route exact path="/admin/ChatBot/edit" render={() =>
          <EditChatbotAIComponent appstore={appstore} />}/>

          <Route exact path="/admin/ChatBot/settings" render={()=><ChatBotSettingsComponent 
                         appstore={appstore}  />}/>          
        </Switch>
      </Router>
    )
  }
})

export default AdminPageLayout;
