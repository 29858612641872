import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import ChatBotpreviewStore from './ChatbotpreviewStore';
import CustomScroll from 'react-custom-scroll';
import ChatBotSettingsStore from './ChatBotSettingsStore';
import imageURLs from '../../../sharedFiles/ImagesURLs';

const ChatPreviewComponent = observer(class ChatPreviewComponent extends Component {
    constructor(props) {
        super(props);
        this.chatBotpreviewStore = new ChatBotpreviewStore(this.props.appstore); // Access the store.
    }

    componentDidMount() {
        this.props.appstore.menuChanged = window.location.pathname; 
		//this.chatBotpreviewStore.isPageLoaded = false;
        this.chatBotSettingsStore = new ChatBotSettingsStore(this.props.appstore);
        this.chatBotSettingsStore.getsettingsdata();
        this.chatBotpreviewStore.fetchEmbeddedFilepaths(this.props.appstore);        
    }
    // componentDidUpdate(prevProps, prevState) {        
    //     this.chatBotpreviewStore.scrollBottom();
    // }
    componentDidUpdate(prevProps, prevState) {
        if (prevState && prevState.messages && this.chatBotpreviewStore.messages) {
            if (prevState.messages.length !== this.chatBotpreviewStore.messages.length) {
                this.scrollBottom();
            }
        } else {
            this.chatBotpreviewStore.scrollBottom(); // Ensure the scroll moves to bottom if messages were previously undefined
        }
    }
    
    componentWillUnmount() {
		window.scrollTo(0, 0);
    }

    render() {
        const { scriptStore, appstore,ai_chatbotuid } = this.props;
        const { messages,isTyping} = this.chatBotpreviewStore;
        const botname = (this.chatBotSettingsStore && this.chatBotSettingsStore.agentDetails && this.chatBotSettingsStore.agentDetails.name)
        ? this.chatBotSettingsStore.agentDetails.name.trim() : "ChatBot";     

        const botimage = (this.chatBotSettingsStore && this.chatBotSettingsStore.agentDetails && this.chatBotSettingsStore.agentDetails.image)
        ? this.props.appstore.resourceurl + this.props.appstore.userInfo.accountsmodel.account_uid + "/" +this.chatBotSettingsStore.agentDetails.image : imageURLs.defaultchatbotimg;

        
        const scrollBarColor = appstore.selectedColor.color;
        
        return (
            <div className="chatPreview" style={{ maxWidth: "550px",height:"400px",marginLeft:'20%' }}>
                <div className="headerTitle active">
                    <h6 className="text-uppercase">{appstore.getLanguageTrans("Test Your ChatBot")}</h6>
                </div>
                <div className="chatBox" style={{height:"374px"}}>
                    <div className="msgArea hoc-chatbody text-left" id='questionArea' style={{ overflowY: 'auto', maxHeight: '100%',scrollbarColor: `${scrollBarColor} #fff` }}>
					<CustomScroll keepAtBottom={true} allowOuterScroll={true}>
					{
                         <div style={{marginLeft:'-17px',maxWidth:'460px'}}>
                         {messages.map((msg, index) => (
                             <div key={index} className={`message ${msg.sender}`}>
                                 {msg.sender === "user" ? (
                                    <>
                                     <img src={(appstore.userInfo.usersmodel.image != '' && appstore.userInfo.usersmodel.image != undefined) ? this.props.appstore.resourceurl + this.props.appstore.userInfo.accountsmodel.account_uid + "/" + appstore.userInfo.usersmodel.image :
								 "#3ba8c5" == appstore.selectedColor.color ? imageURLs.avatar_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.avatar_seagreen : "#ec407a" == appstore.selectedColor.color ?imageURLs.avatar_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.avatar_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.avatar_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.avatar_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.avatar_voilet : 
								 "#039be6" == appstore.selectedColor.color ? imageURLs.avatar_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.avatar_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.avatar_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.avatar_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.avatar_skyblue : imageURLs.avatar_steelblue} alt="avatar" className="avatar bg_circle img-fluid" />
                                     <strong> You : </strong>
                                     </>
                                 ) : (
                                     <>                                         
                                        <img src={botimage} style={{ width: "30px", height: "30px", borderRadius: "50px" }} className="img-fluid" />
                                        <strong> {botname} : </strong>
                                     </>
                                 )}
                                 {msg.text}
                             </div>
                         ))}
                         {isTyping && (
                             <div className="message ai typing-indicator">
                                 <img src={botimage} style={{ width: "30px", height: "30px", borderRadius: "50px" }} className="img-fluid" />
                                 <span style={{marginLeft:"10px"}}><strong>{botname} : </strong></span>
                                 <span className="dot" style={{backgroundColor:appstore.selectedColor.color,marginLeft:"10px"}}></span>
                                 <span className="dot" style={{backgroundColor:appstore.selectedColor.color}}></span>
                                 <span className="dot" style={{backgroundColor:appstore.selectedColor.color}}></span>
                             </div>
                         )}
                     </div>
					}
					</CustomScroll>
                    </div>
                    <div>
                        <Form.Group as={Row} controlId="formGroupMsg">
                            <Col sm="12" className="pl-0">
                                <Form.Control type="text" 
                                    placeholder={appstore.getLanguageTrans("Enter message")}
                                    value={scriptStore.chatUserData}
                                    onFocus={() => (scriptStore.previewOnFocus = true)}
                                    onBlur={() => (scriptStore.previewOnFocus = false)}
                                    onChange={(e) => (scriptStore.chatUserData = e.target.value)}
                                    disabled={this.chatBotpreviewStore.isWaitingForResponse}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            this.chatBotpreviewStore.sendChatData(scriptStore,appstore,ai_chatbotuid);
                                        }
                                    }}
                                />
                            </Col>
                        </Form.Group>
                        <div className="row m-0">
                            <div className="col-12 formBtns">
                                <div className="pullLeft">
                                    <Button className="btn_send" id="send_msg" 
                                        onClick={() => this.chatBotpreviewStore.sendChatData(scriptStore,appstore,ai_chatbotuid)}  disabled={this.chatBotpreviewStore.isWaitingForResponse}>
                                        <p>{appstore.getLanguageTrans("Send")}</p>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});

export default ChatPreviewComponent;