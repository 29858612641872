import React, { Component } from "react";
import { observer } from "mobx-react";
import ScrapWebsiteStore from "./ScrapWebsiteStore";
import imageURLs from '../../../sharedFiles/ImagesURLs';
import { Button, Modal } from "react-bootstrap";
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';
import EditChatbotAISuburlsModal from "./editChatbotAISuburlsModal";
import axios from "axios";
import navigationStore from '../../../sharedFiles/navigation-store';
import ConfirmationComponent from '../../../sharedComponents/confirmationPopup/ConfirmationComponent';

const override = css`
  position: absolute; 
  top: 289px;
  z-index: 999;
  left: 35%; 
  opacity: 0.6;
  position: relative;
`;

const TOKEN_LIMIT = 10000; // Token Limit for scrapping words

const EditChatbotAIComponent = observer(class EditChatbotAIComponent extends Component {
  constructor(props) {
    super(props);
    this.scrapWebsiteStore = new ScrapWebsiteStore();
    this.state = {
      showModal: false,
      currentSubUrls: [],
      currentSubCheckboxStates: {},
      currentMainUrlName: '',
      checkboxStates: {},
      totalTokens: 0,
      continueDisabled: false,
      tooltipMessage: '',
      initialWebEmbdStatus: {},
      tokenexcced_msg: ""
    };
  }

  componentDidMount() {
    this.props.appstore.menuChanged = window.location.pathname;
    this.fetchWebsiteData();
  }

  componentDidUpdate(prevProps, prevState) { 		
		if (prevState.websiteUid !== this.props.appstore.selectedWebisiteUid) {  
			if(!this.props.appstore.componentDidUpdate){
				this.scrapWebsiteStore.ispageLoaded = false;
				this.fetchWebsiteData();
				this.props.appstore.componentDidUpdate=true;
			}			
			  this.setState({websiteUid:this.props.appstore.selectedWebisiteUid});
		}
	}

  fetchWebsiteData = () => {
    this.scrapWebsiteStore.getWebsitePageNames(this.props.appstore).then(() => {
      const { website_pagename } = this.scrapWebsiteStore; 
      const checkboxStates = {};
      const initialWebEmbdStatus = {};
      let totalTokens = 0;
     
      website_pagename.forEach(website => {
        const subUrls = website.website_suburlname ? website.website_suburlname.split(',') : [];
        const subTokens = website.tokensize ? website.tokensize.split(',').map(Number) : [];
        const enable_status = website.embed_enbale_disable ? website.embed_enbale_disable.split(',') : [];
        const embedded_status = website.webembdstatus ? website.webembdstatus.split(',') : [];

        const subCheckboxStates = subUrls.reduce((acc, subUrl, index) => {
        const isCheckembedstatus = enable_status[index] === 'yes'; 
        const isChecked = embedded_status[index] === 'yes';
          
            acc[subUrl] = isCheckembedstatus;          
            if (isChecked) totalTokens += subTokens[index] || 0;
            return acc;
      }, {});
      
      const aiChatbotUrldetailsIds = website.ai_chatbot_urldetailsids ? website.ai_chatbot_urldetailsids.split(',') : [];
      const webscrapfilePaths = website.webscrapfilePath ? website.webscrapfilePath.split(',') : [];
      const webembdFilepaths = website.webembdFilepath ? website.webembdFilepath.split(',') : [];      
        // Store the initial webembdstatus for each sub-url
        initialWebEmbdStatus[website.websiteurl_name] = { ...subCheckboxStates };        
        checkboxStates[website.websiteurl_name] = {
          isChecked: website.webscrapStatus === 'yes',
          subUrls: subUrls,
          subTokens: subTokens,
          subCheckboxStates: subCheckboxStates,
          webscrapfile_Paths: webscrapfilePaths,
          webembd_Filepath: webembdFilepaths,
          ai_chatbot_details_id: website.ai_chatbot_details_id,
          ai_chatbot_id: website.ai_chatbot_id,
          ai_chatbot_urldetails_ids: aiChatbotUrldetailsIds,
          ai_chatbotuid : website.ai_chatbotuid,
          embedded_status : embedded_status
        };        
      });  
      this.setState({ 
        checkboxStates, 
        totalTokens, 
        initialTokens: totalTokens, // Set the initial tokens
        continueDisabled: 0 > TOKEN_LIMIT, 
        initialWebEmbdStatus 
      });
      this.updateTooltipMessage(totalTokens);
    });
  };  

  updateTooltipMessage = (totalTokens) => {
    const tooltipMessage = 0 > TOKEN_LIMIT ? 'Your token limit exceeds' : '';
    const tokenexcced_msg = 0 > TOKEN_LIMIT ? "You have reached the limit of 10,000 words per scan as per your plan. Please unselect a few pages to proceed or consider upgrading your plan for higher limits." : "";
    this.setState({ tooltipMessage,tokenexcced_msg });
  };

  openEditModal = (website) => {
    const { subUrls, subCheckboxStates } = this.state.checkboxStates[website.websiteurl_name];

    const updatedSubCheckboxStates = {};
    subUrls.forEach(subUrl => {
      const subPage = this.scrapWebsiteStore.website_pagename.find(page => page.website_suburlname.includes(subUrl.trim()));
      if (subPage) {
        updatedSubCheckboxStates[subUrl] = subCheckboxStates[subUrl] || subPage.webembdstatus === 'yes';
      }
    });

    this.setState({
      showModal: true,
      currentSubUrls: subUrls,
      currentSubCheckboxStates: updatedSubCheckboxStates,
      currentMainUrlName: website.websiteurl_name
    });
  };

  closeEditModal = () => {
    const { currentMainUrlName, currentSubCheckboxStates } = this.state;
    this.setState((prevState) => ({
      showModal: false,
      checkboxStates: {
        ...prevState.checkboxStates,
        [currentMainUrlName]: {
          ...prevState.checkboxStates[currentMainUrlName],
          subCheckboxStates: currentSubCheckboxStates
        }
      },
      currentSubUrls: [],
      currentSubCheckboxStates: {},
      currentMainUrlName: ''
    }));
  };

  updateSubCheckboxStates = (mainUrl, isChecked) => {
    const subUrls = this.state.checkboxStates[mainUrl].subUrls;
    const newSubCheckboxStates = {};
    subUrls.forEach(subUrl => {
      newSubCheckboxStates[subUrl] = isChecked;
    });

    return newSubCheckboxStates;
  };

  handleMainCheckboxChange = (mainUrl) => {
    this.setState((prevState) => {
      const newCheckboxStates = { ...prevState.checkboxStates };
      const isChecked = !newCheckboxStates[mainUrl].isChecked;
      newCheckboxStates[mainUrl].isChecked = isChecked;
  
      const newSubCheckboxStates = this.updateSubCheckboxStates(mainUrl, isChecked);
      newCheckboxStates[mainUrl].subCheckboxStates = newSubCheckboxStates;
  
      // Calculate totalTokens
//      let totalTokens = prevState.initialTokens;
    let totalTokens = 0;      
      Object.values(newCheckboxStates).forEach(state => {
        if (state.isChecked) {
          state.subTokens.forEach(token => {            
            totalTokens += token;
          });
        }
      });      
      // Update tooltip message based on totalTokens
      const tooltipMessage = 0 > TOKEN_LIMIT ? "Your token limit exceeded " : "";
      const tokenexcced_msg = 0 > TOKEN_LIMIT ? "You have reached the limit of 10,000 words per scan as per your plan. Please unselect a few pages to proceed or consider upgrading your plan for higher limits." : "";
      return {
        checkboxStates: newCheckboxStates,
        currentSubCheckboxStates: newSubCheckboxStates,
        totalTokens,
        continueDisabled: 0 > TOKEN_LIMIT,
        tooltipMessage,
        tokenexcced_msg
      };
    });
  };

  handleSubCheckboxChange = (subPageUrl) => {
    this.setState((prevState) => {
      const newSubCheckboxStates = { ...prevState.currentSubCheckboxStates };
      newSubCheckboxStates[subPageUrl] = !newSubCheckboxStates[subPageUrl];
  
      const mainUrl = prevState.currentMainUrlName;
      const mainCheckboxState = prevState.checkboxStates[mainUrl];
      const subTokens = prevState.checkboxStates[mainUrl].subTokens;
      const tokenIndex = prevState.checkboxStates[mainUrl].subUrls.findIndex(url => url === subPageUrl);
      const tokenSize = subTokens[tokenIndex] || 0;
      const totalTokensChange = newSubCheckboxStates[subPageUrl] ? tokenSize : -tokenSize;
  
      const updatedCheckboxStates = {
        ...prevState.checkboxStates,
        [mainUrl]: {
          ...mainCheckboxState,
          subCheckboxStates: newSubCheckboxStates
        }
      };
  
      // Calculate new totalTokens
      const newTotalTokens = prevState.totalTokens + totalTokensChange;
      this.updateTooltipMessage(newTotalTokens);
  
      // If any sub-URL is checked, set the main URL checkbox to true
      const anySubUrlsChecked = Object.values(newSubCheckboxStates).some(isChecked => isChecked);
  
      updatedCheckboxStates[mainUrl].isChecked = anySubUrlsChecked;
  
      return {
        currentSubCheckboxStates: newSubCheckboxStates,
        checkboxStates: updatedCheckboxStates,
        totalTokens: newTotalTokens,
        continueDisabled: 0 > TOKEN_LIMIT,
        tooltipMessage: 0 > TOKEN_LIMIT ? "Your token limit exceeded" : "",
        tokenexcced_msg: 0 > TOKEN_LIMIT ? "You have reached the limit of 10,000 words per scan as per your plan. Please unselect a few pages to proceed or consider upgrading your plan for higher limits." : ""
      };
    });
  };  

  createEmbeddings = async () => {
    const { checkboxStates, initialWebEmbdStatus } = this.state;
    const subUrlData = [];   
    this.scrapWebsiteStore.ispageLoaded = false;
    Object.keys(checkboxStates).forEach(mainUrl => {
      const mainUrlChecked = checkboxStates[mainUrl].isChecked ? "yes" : "no" ; 
      const { subUrls, subCheckboxStates, webscrapfile_Paths, webembd_Filepath, ai_chatbot_details_id, ai_chatbot_id,ai_chatbot_urldetails_ids,ai_chatbotuid,embedded_status } = checkboxStates[mainUrl];
     
      subUrls.forEach((subUrl,index) => {
        subUrlData.push({
          mainUrl,mainUrlChecked,url : subUrl,subUrlChecked: subCheckboxStates[subUrl]  ? "yes" : "no",
          initialWebEmbdStatus: initialWebEmbdStatus[mainUrl][subUrl],
          webscrapfilePath : webscrapfile_Paths[index],
          webembdFilepath : webembd_Filepath[index],
          ai_chatbot_details_id,ai_chatbot_id,
          ai_chatbot_urldetails_id : ai_chatbot_urldetails_ids[index],
          ai_chatbotuid,webembdstatus : embedded_status[index]
        });
      });
    });
   await this.processEmbeddingsAndInsert(subUrlData);
  };

  processEmbeddingsAndInsert = async (allFilePaths) => {
    
    // allFilePaths.forEach(filePathObj => {
    //   const mainUrl = filePathObj.mainUrl;
    //  // filePathObj.scrapstatus = filePathObj.mainUrlChecked ? 'yes' : 'no'; // Add scrapstatus to each Object
    //  filePathObj.webembdstatus = filePathObj.mainUrlChecked ;
    // });
  
    await this.insertIntoDatabase(allFilePaths);
   
    /*const processNextFile = (index) => {
      if (index >= allFilePaths.length) {
        this.updateStatusAsCompleted();
        return;
      }
      const { mainUrl, subUrl, webscrapfilePath, webembd_Filepath, subUrlChecked, initialWebEmbdStatus, ai_chatbot_details_id, ai_chatbot_id,mainUrlChecked,ai_chatbot_urldetails_id,scrapstatus } = allFilePaths[index];    
      this.scrapWebsiteStore.ai_chatbot_id=ai_chatbot_id
      const embdstatus = subUrlChecked ? 'yes' : 'no';    
      if (initialWebEmbdStatus) {        
        this.insertIntoDatabase(ai_chatbot_urldetails_id, scrapstatus, ai_chatbot_details_id, ai_chatbot_id,mainUrlChecked,"Success",webembd_Filepath, () => processNextFile(index + 1));
      } else if (embdstatus === 'yes') {        
        this.performEmbedding(ai_chatbot_urldetails_id, subUrl, webscrapfilePath, scrapstatus, ai_chatbot_details_id, ai_chatbot_id,mainUrlChecked, () => processNextFile(index + 1));
      } else {        
        this.insertIntoDatabase(ai_chatbot_urldetails_id, scrapstatus, ai_chatbot_details_id, ai_chatbot_id,mainUrlChecked,"","", () => processNextFile(index + 1));
      }
    };
    processNextFile(0);*/
  };

  storeFilePathsInRedis = async (allFilePaths, keyPrefix = "scrap_filepaths") => {
    console.log("Total file paths:", allFilePaths.length);

    // Filter valid file paths
    let validEntries = allFilePaths.filter(obj => obj.webscrapfilePath && obj.webscrapfilePath.trim());
    if (validEntries.length === 0) {
        console.error("No valid file paths to store in Redis.");
        return null;
    }

    console.log("Valid file paths:", validEntries.length);
    
    // Redis Key
    const redisKey = `${keyPrefix}:${Date.now()}`;
    
    // Batch Processing
    const CHUNK_SIZE = 300; // Adjust this if needed
    for (let i = 0; i < validEntries.length; i += CHUNK_SIZE) {
        const chunk = validEntries.slice(i, i + CHUNK_SIZE);

        try {
            await Promise.all(chunk.map(async (file) => {
                return fetch(`${this.props.appstore.chatbotnodeurl}scrapfilepathsstoreInRedis`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ key: redisKey, chunk: JSON.stringify(file) }),
                }).then(res => {
                    if (!res.ok) throw new Error(`HTTP error! Status: ${res.status}`);
                    return res.json();
                }).catch(err => {
                    console.error(`Failed to store object:`, err);
                });
            }));

            console.log(`Stored batch ${i / CHUNK_SIZE + 1}/${Math.ceil(validEntries.length / CHUNK_SIZE)}`);
        } catch (error) {
            console.error("Error storing batch in Redis:", error);
        }
    }

    return redisKey;
};

  insertIntoDatabase = async (allFilePaths) => {

    const redisKey = await this.storeFilePathsInRedis(allFilePaths);// Store in redis all the scrap filepaths for avoiding data loss

    if (!redisKey) {
      //alert("Failed to store file paths in Redis. Try again.");
      return;
    }
    //navigationStore.push('/admin/ChatBot/processingChatbot');
    this.props.appstore.navigate("/admin/ChatBot/ChatbotHomeComponent");
    const { account_uid, account_id } = this.props.appstore.userInfo.accountsmodel;
    const formData = new FormData();
  
    formData.append("user_uid", account_uid);
    formData.append("website_uid", this.props.appstore.selectedWebisiteUid);    
    //formData.append("webscrapfilePaths", JSON.stringify(allFilePaths));
    formData.append("account_id", account_id);
    formData.append("basepath", this.props.appstore.chatbotfilepath);
    formData.append("chatbotnodeurl", this.props.appstore.chatbotnodeurl);
    formData.append("scrap_filepathsredisKey", redisKey);
  
    try {
      await axios({
        method: 'POST',
        url: this.props.appstore.url + "updateChatbotwebsiteDetails.json",
        headers: { 'content-type': 'multipart/form-data', 'charset': 'utf-8' },
        data: formData,
      });
  
      // Navigate after API call is successful
     // navigationStore.push('/admin/ChatBot/ChatbotHomeComponent');
    
    } catch (error) {
      console.error('There was a problem with the save operation:', error);
    }
  };  

  /*performEmbedding =  (ai_chatbot_urldetails_id, subUrl, webscrapfilePath, scrapstatus, ai_chatbot_details_id, ai_chatbot_id,mainUrlChecked, callback) => {
   
     fetch(`${this.props.appstore.chatbotnodeurl}create-embeddings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
      //  filePath : webscrapfilePath,        
      //  user_id: this.props.appstore.userInfo.accountsmodel.account_uid,
      //  websiteurl: subUrl 

        filePath: webscrapfilePath,
        user_id: this.props.appstore.userInfo.accountsmodel.account_uid,
        websiteurl: subUrl,
        account_id: this.props.appstore.userInfo.accountsmodel.account_id,
        basepath: this.props.appstore.chatbotfilepath
      })
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }).then(data => {
      console.log("Embedding Completed for filePath:", data,data.scrapedFilePath);
      this.insertIntoDatabase(ai_chatbot_urldetails_id, scrapstatus, ai_chatbot_details_id, ai_chatbot_id,mainUrlChecked,data.status,data.scrapedFilePath,callback);
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
      this.insertIntoDatabase(ai_chatbot_urldetails_id,scrapstatus, ai_chatbot_details_id, ai_chatbot_id,mainUrlChecked,"failed",null,callback);
    });
  };

  insertIntoDatabase = (ai_chatbot_urldetails_id,embdstatus, ai_chatbot_details_id, ai_chatbot_id,mainUrlChecked,embdRemarks,embdFilepath,callback) => {    
    const formData = new FormData();
    formData.append("ai_chatbot_id", ai_chatbot_id);
    formData.append("website_uid", this.props.appstore.selectedWebisiteUid);    
    formData.append("webscrapStatus", mainUrlChecked ? 'yes' : 'no');
    formData.append("webembdstatus", embdstatus);
    formData.append("webembdFilepath", embdFilepath);
    formData.append("webembedRemarks", embdRemarks);
    formData.append("ai_chatbot_details_id",ai_chatbot_details_id);
    formData.append("ai_chatbot_urldetails_id",ai_chatbot_urldetails_id);
    formData.append("status",0);
  
    axios({
      method: 'POST',
      url: this.props.appstore.url + "updateChatbotwebsiteDetails.json",
      headers: { 'content-type': 'multipart/form-data','charset':'utf-8' },
      data: formData,
    }).then((result) => {      
      callback();
    }).catch((error) => {
     // console.error('There was a  problem with the save operation:', error);
      callback();
    });
  };

  updateStatusAsCompleted = () => {    
    const formData = new FormData();
    formData.append("ai_chatbot_id",this.scrapWebsiteStore.ai_chatbot_id);
    formData.append("website_uid", this.props.appstore.selectedWebisiteUid);    
    formData.append("status",1);    
    axios({
      method: 'POST',
      url: this.props.appstore.url + "updateChatbotwebsiteDetails.json",
      headers: { 'content-type': 'multipart/form-data','charset':'utf-8' },
      data: formData,
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }).then(data => {
      console.log("Status Updated to Completed:", data);
    }).catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
  }; */

  cancelForm = () => {
    this.scrapWebsiteStore.resetForm();
  }

  saveAction = () => {
    this.scrapWebsiteStore.confirmembeddedstatus = true;
  };

  cancelAction = () => {
    // navigationStore.push('/admin/ChatBot/ChatbotHomeComponent');
    this.props.appstore.navigate("/admin/ChatBot/ChatbotHomeComponent");
  };

  render() {
    const { appstore } = this.props;
    const { currentSubUrls, currentSubCheckboxStates, currentMainUrlName, showModal, checkboxStates, totalTokens, continueDisabled, tooltipMessage, tokenexcced_msg } = this.state;

    if (!this.scrapWebsiteStore.ispageLoaded) {
      return (
        <div className="adminloader">
          <GridLoader css={override} size={20} margin={2} color={appstore.selectedColor.color} />
        </div>
      );
    }

    const { website_pagename } = this.scrapWebsiteStore;
    const websiteScrapURL = website_pagename.length > 0 ? website_pagename[0].websiteScrapURL : '';

    return (
      <div className="innercontent_wrapper w-100 scriptWrapper">
        <div className="header_title row align-items-center m-0">
          <img src={imageURLs.websitePageIcon} alt="faq" className="img-fluid" />
          <h4 className="text-left">{appstore.getLanguageTrans("Website")}</h4>
        </div>
        <div className="row fileTransferWrapper">
          <div className="col-12 w-100 pr-0">
            <div className="settingFormWrapper box">
              <div className="input-container">
                <h3 style={{ color: appstore.selectedColor.color }}>{websiteScrapURL}</h3>
              </div>
              <div className="row">
                {website_pagename.map((website, index) => (
                  <div className="col-3 pagebox" key={index}>
                    <div className="cardbox box">
                      <div className="col-2 checkboxalign">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={checkboxStates[website.websiteurl_name] && checkboxStates[website.websiteurl_name].isChecked}
                            onChange={() => this.handleMainCheckboxChange(website.websiteurl_name)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="col-12 text-left text-wrapper" style={{ wordWrap: 'break-word' }}>
                        <span style={{cursor:'pointer'}}  onClick={() => this.openEditModal(website)}>
                          <span style={{ fontWeight: 'bold' }}>{website.websiteurl_name}</span><br />                          
                          <span className="hover-link">{website.website_suburlname ? website.website_suburlname.split(',').length === 1 
                            ? "1 page" : `${website.website_suburlname.split(',').length} pages` : "0 pages"}<img src={imageURLs.editIcon} className="edit-icon" alt="edit icon" />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row m-0">
                <div className="col-12 formBtns">
                  <div className="col-8" style={{float:'left'}}>
                    <p style={{fontWeight:'bold',color:'red',textAlign:'left'}}>{appstore.getLanguageTrans(tokenexcced_msg)}</p>
                  </div>
                  <div className="col-4 pullRight">
                    <Button className="btn_clr" variant="light" onClick={this.cancelAction}>
                      <p>{appstore.getLanguageTrans("Cancel")}</p>
                    </Button>
                    <Button className="btn_clr" variant="light" onClick={this.saveAction}  data-tooltip={appstore.getLanguageTrans(tooltipMessage)} data-tooltip-conf="top">
                      <p>{appstore.getLanguageTrans("Continue")}</p>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <EditChatbotAISuburlsModal
          show={showModal}
          handleClose={this.closeEditModal}
          appstore={appstore}
          subUrls={currentSubUrls}
          subCheckboxStates={currentSubCheckboxStates}
          mainurlname={currentMainUrlName}
          handleSubCheckboxChange={this.handleSubCheckboxChange}
        />
         {/* Embedding Confirmation pop up */}
         {
            this.scrapWebsiteStore.confirmembeddedstatus &&
            <ConfirmationComponent show={this.scrapWebsiteStore.confirmembeddedstatus} store={this.scrapWebsiteStore}
            title="Confirm "
            body_html="<p>Are you sure you want to continue .<p>"
            cancel_btn="Cancel" save_btn="ok" 
            callBack={() => { 
            this.createEmbeddings(); 
            this.scrapWebsiteStore.confirmembeddedstatus = false; 
            }}
            closePopup={() => this.scrapWebsiteStore.confirmembeddedstatus=false} appStore = {appstore}/>
          }
      </div>
    );
  }
});

export default EditChatbotAIComponent;