import { observer } from "mobx-react";
import React, { Component } from 'react';
import imageURLs from '../../../sharedFiles/ImagesURLs';
import {ClipLoader,GridLoader } from "react-spinners";
import { css } from '@emotion/core';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';

const processoverride = css`
position: absolute; 
top: 10px;
position: relative;
margin-right: 10px;`

const btn_pos={
    marginTop:5
} 

    const ChatBotProcessingComponent = observer(({appstore,scrapWebsiteStore}) => {

        return (
            <div className="innercontent_wrapper w-100 scriptWrapper">
                <div className="header_title row align-items-center m-0" id="header_title">
                    <img src={imageURLs.chatbotknowledge} alt="Chat Archive" className="img-fluid"/>
                    <h4 className="text-left">{appstore.getLanguageTrans("Training")} {appstore.getLanguageTrans("Chatbot")}</h4>
                </div>
                <div className="row fileTransferWrapper">
			        <div className="col-12 w-100 pr-0">
				        <div className="settingFormWrapper archiveFormWrapper box">
                            <Row className="top_Row" id="top_row">
                                <Col xs="12" lg="9" md="9" sm="9">
                                    <Form>
                                        <Form.Group as={Row}>
                                            <Col sm="12" lg="12">                                                
                                                <span><ClipLoader css={processoverride} size={30} margin={2} color={appstore.selectedColor.color} /></span>
                                                <span>{appstore.getLanguageTrans("We're processing your data to improve chatbot responses.")} {appstore.getLanguageTrans("This may take a few minutes. Once training is complete, your chatbot will be ready to use!")}</span>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </Col>                                
                                <Col xs="5" lg="2" md="6" sm="6" className="top_refBtn">
                                    <Form>
                                        <Form.Group as={Row} >
                                            <Button className="custom_btn addBtn text-uppercase top_btn archiverefresh" onMouseOver={() => scrapWebsiteStore.refreshimagesetonhover()} onMouseOut={() => scrapWebsiteStore.refreshimagesetonhoverout(appstore.selectedColor.color)}  style={btn_pos} onClick={function() {scrapWebsiteStore.processingpageloadded=false; scrapWebsiteStore.handleRefresh(appstore);}}>
                                                <img className="img-fluid" id="archiveimg" style={{cursor:"pointer"}} src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.refresh_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.refresh_seagreen : "#ec407a" == appstore.selectedColor.color ?  imageURLs.refresh_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.refresh_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.refresh_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.refresh_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.refresh_voilet : 
                                                "#039be6" == appstore.selectedColor.color ? imageURLs.refresh_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.refresh_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.refresh_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.refresh_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.refresh_skyblue : imageURLs.refresh_steelblue} alt=""   />   {appstore.getLanguageTrans("Refresh")} 
                                            </Button>								        
                                        </Form.Group>
                                    </Form>								 
                                </Col>
                            </Row>
                            </div>
                    </div>
                </div>                
            </div>
        );
    });

export default ChatBotProcessingComponent;