import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import imageURLs from '../../sharedFiles/ImagesURLs';
import navigationStore from '../../sharedFiles/navigation-store';
import WebsiteComponent from './components/WebsiteComponent';
import ConfirmationComponent from '../../sharedComponents/confirmationPopup/ConfirmationComponent';
import HomeComponentStore from './HomeComponentStore';
import WarningComponent from '../../sharedComponents/confirmationPopup/WarningComponent';
import axios from 'axios';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

const HomeComponent = observer(class HomeComponent extends Component {

	constructor (props) {
        super(props);
        this.homeComponentStore = new HomeComponentStore(this.props.appstore);
    }

	componentDidMount(){
		this.props.appstore.menuChanged = window.location.pathname;
		//this.homeComponentStore.getWebsites(this.props.appstore);
		this.homeComponentStore.websiteDetails=this.props.appstore.userInfo.allwebistes;
		// console.log(this.homeComponentStore.websiteDetails);
		// console.log("this.homeComponentStore.websiteDetails");
		this.homeComponentStore.isPageLoaded=true;

		var date = new Date().getDate();
        var month = new Date().getMonth() + 1;
        var year = new Date().getFullYear();

        this.getwebsiteRecords(date + '-' + month + '-' + year);
	}

	componentWillUnmount() {
		window.scrollTo(0, 0);
    }
	
	addNewSite(){
		this.props.appstore.addNewSite=true;
		this.props.appstore.navigate('/admin/setup/widgets');
	}
	
	

   secondsToTime(secs) {
	var hours = Math.floor(secs / (60 * 60));

	var divisor_for_minutes = secs % (60 * 60);
	var minutes = Math.floor(divisor_for_minutes / 60);

	var divisor_for_seconds = divisor_for_minutes % 60;
	var seconds = Math.ceil(divisor_for_seconds);

	if (hours == 0 && minutes == 0) {
		return seconds + "s";
	}
	if (hours == 0) {
		return minutes + "m:" + seconds + "s";
	}
	else {
		return hours + "h:" + minutes + "m:" + seconds + "s";
	}
}

   getwebsiteRecords = (dateRange = null) => {
	const today = moment();
	let since_from_today = today.clone().subtract(0, "days");
	const data = {
		"todaydate": dateRange != null ? since_from_today.format("DD-MM-YYYY HH:mm:ss"):this.state.value.start.format("DD-MM-YYYY HH:mm:ss"),
			}
	let websiteObject={};
	axios({
		method: 'POST',
		url: this.props.appstore.url + 'getwebsiterecords.json',
		headers: { 'content-type': 'application/json' },
		data: data,
	}).then(result => {
		if(result.data.length==1){
			if(result.data[0].logoutstatus==1){
			  this.props.appstore.logout();
			}
		  }
		let invitationCount=0;
		let chatsCount=0;
		let chatsTime=0;

		for(let i in result.data){
			websiteObject[result.data[i].website_uid]= websiteObject[result.data[i].website_uid] ? websiteObject[result.data[i].website_uid] :{}
		}
		for(let i in result.data){
		if(websiteObject[result.data[i].website_uid]){
			
			let website = websiteObject[result.data[i].website_uid];
			if (result.data[i].type==="chats"){
				chatsCount = Number(chatsCount) + Number(result.data[i].cnt) 
				chatsTime  = Number(chatsTime) + Number(result.data[i].time)				
			  	website["chats"] = chatsCount ;
			  	website["totalChatTime"] = this.secondsToTime(chatsTime) ;			 
			}
			else if (result.data[i].type==="invitations"){

				invitationCount= Number(invitationCount)+Number( result.data[i].cnt)
                website["invitations"] = invitationCount + result.data[i].cnt;
			}
			
		}
	}

	for(let website of this.homeComponentStore.websiteDetails){
		let overviewData = websiteObject[website.website_uid]; 
		website.chats = overviewData ? overviewData.chats!=undefined ?  overviewData.chats: "0" : "0";
		website.totalChatTime = overviewData ? overviewData.totalChatTime!=undefined ? overviewData.totalChatTime: "0" :"0";
		website.invitations=overviewData ? overviewData.invitations!=undefined ? overviewData.invitations: "0":"0";
	  }

	    const data  = this.props.appstore.getActiveWebsiteWidget();
		data.then(result =>{
			 
			for(let website of this.homeComponentStore.websiteDetails){
				 if(result.data[website.website_uid])
					website.active =true;
				else
				website.active = false;	
			}
		})

		this.props.appstore.global_errorcount=0;
	})
	.catch(error => {
		// this.props.appstore.logout();
				console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
	})

} 

   getContent =() =>{
	      if(!this.props.appstore.isOwner){
			  if(this.props.appstore.userInfo.accountsmodel.display_buy_now==1){
			  return "<p>Your Free plan includes "+this.props.appstore.userInfo.accountplan.max_websites+" website. If you would like to add more websites to your account, please ask "+this.props.appstore.userInfo.usersmodel.ownerRealname+", the account owner, to upgrade the account.</p>"
			  }else{
				return "<p>Your plan includes "+this.props.appstore.userInfo.accountplan.max_websites+" websites. If you would like to add more websites to your account, please ask "+this.props.appstore.userInfo.usersmodel.ownerRealname+", the account owner, to upgrade the account.</p>"
			  }
			}else{
				if(this.props.appstore.userInfo.accountsmodel.display_buy_now==1){
			 		 return "<p style='font-family:Segoe UI'>Your Free plan includes "+this.props.appstore.userInfo.accountplan.max_websites+" websites. If you would like to add more websites to your account, please upgrade to the Platinum plan and add more websites for an extra fee.</p>"
				}else{
					//return "<p style='font-family:Segoe UI'>Your plan includes "+this.props.appstore.userInfo.accountsmodel.max_websites+" websites. If you would like to set up more websites, you have two options:</p> <ul> <li style='font-family:Segoe UI'> Upgrade to the Platinum plan, which includes 10 websites.</li> <li style='font-family:Segoe UI'> Add more websites to your plan for an extra fee.</li> </ul> <p style='font-family:Segoe UI'>In both cases you will start a new subscription period. Unused funds will be pro-rated.</p>"
					return "<p style='font-family:Segoe UI'>Your plan includes "+this.props.appstore.userInfo.accountplan.max_websites+" websites. If you would like to set up more websites, you can add them to your plan for an extra fee. </p> <p style='font-family:Segoe UI'>When you upgrade, a new subscription period will start. Unused funds will be pro-rated.</p>"
				}
			}
   }

	render(){
        if(!this.homeComponentStore.isPageLoaded){
			return (<div></div>)
		}
		var self = this;
		const { appstore
		         } = this.props;

		return (
			<div className="innercontent_wrapper w-100">
				<div className="websitesWrapper">
					<div className="websiteDetailsWrapper row" >
						{
							this.homeComponentStore.websiteDetails.map(function(website,websiteIndex) {
								return (
									<WebsiteComponent key={websiteIndex} homeComponentStore={self.homeComponentStore}
										  appstore = {appstore}
										  website = {website} />
								)
							})
						
						}
						{ !appstore.maxWebsiteCrossed() &&
							<div className="websitesDetails col-12 col-sm-6 col-md-4">
								<div className="addNewWebsite_tour box col-12" style={(appstore.tourcurrent_step==0 && appstore.helpTourStarted) ? {paddingTop:100 , paddingBottom:68,pointerEvents:'none'} : {paddingTop:100 , paddingBottom:68,cursor:"pointer"}}>
									<div className="noneWrapper" onClick={() =>this.addNewSite()}>
										<img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.add_steelblue2 : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.add_seagreen2 : "#ec407a" == appstore.selectedColor.color ?  imageURLs.add_pink2 : "#6e4c42" == appstore.selectedColor.color ?imageURLs.add_brown2 : "#234e91" == appstore.selectedColor.color ?imageURLs.add_navyblue2 : "#e89712" == appstore.selectedColor.color ?imageURLs.add_yellow2 : "#b968c7" ==appstore.selectedColor.color ?imageURLs.add_voilet2 : 
										"#039be6" == appstore.selectedColor.color ? imageURLs.add_blue2 : "#00887a" == appstore.selectedColor.color ? imageURLs.add_darkgreen2 : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.add_green2 : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.add_grey2 : "#64a9e0"== appstore.selectedColor.color ? imageURLs.add_skyblue2 : imageURLs.mute_steel_blue2} alt="" className="img-fluid" />
										<p style={{color:appstore.selectedColor.color,fontfamily:"Segoe UI Bold",fontSize:13,fontWeight:700,padding:13,textTransform:"uppercase",fontFamily:"Segoe UI Bold"}}>{appstore.getLanguageTrans("Add New Site")}</p>
									</div>
								</div>
							</div>
						}

						{ appstore.maxWebsiteCrossed() &&
							<div className=" websitesDetails col-12 col-sm-6 col-md-4">
								<div className="addNewWebsite_tour box col-12" style={(appstore.tourcurrent_step==0 && appstore.helpTourStarted) ? {pointerEvents:'none'} :{}}>
									<div className="addWrapper" onClick={() =>this.homeComponentStore.payDollar=true}>
										<center>
										<p className="websitePayDollar">$</p>
										</center>
									</div>
								</div>
							</div>
							// <div className=" websitesDetails col-12 col-sm-6 col-md-4">
							// 	<div className=" box col-12" style={(appstore.tourcurrent_step==0 && appstore.helpTourStarted) ? {pointerEvents:'none'} :{}}>
							// 	<div className="image-container" onClick={() =>this.homeComponentStore.payDollar=true}>
							// 	<img src={imageURLs.priceimage} alt="Image" className="img-fluid" />
							// 	</div>
							// 	</div>
							// </div> 
						}
					</div>
				
				</div>

                {
					this.homeComponentStore.deleteWebsite &&
					<ConfirmationComponent show={this.homeComponentStore.deleteWebsite}
					store={this.homeComponentStore}
					title="Delete website"
					body_html="<p>Are you sure you want to delete the website and all settings related to it ? The operation cannot be undone, the removed data cannot be restored.<p>"
					cancel_btn="Cancel"
					save_btn="Delete" 
					callBack={() => this.homeComponentStore.confirmdeleteWebsite(appstore)}
					closePopup={() => this.homeComponentStore.deleteWebsite=false}
					appStore = {appstore}/>
				}

                {/* {
					this.homeComponentStore.lastWebsite && 
					<ConfirmationComponent show={this.homeComponentStore.lastWebsite}
					store={this.homeComponentStore}
					title="Delete website"
					body_html="<p>Sorry, you can not delete all websites.<p>"
					singleButton={true}
					cancel_btn="Okay"
					closePopup={() => this.homeComponentStore.lastWebsite=false}
					appStore = {appstore}/>
				} */}

				{
					this.homeComponentStore.payDollar &&
					<ConfirmationComponent show={this.homeComponentStore.payDollar}
					store={this.homeComponentStore}
					title="Upgrade Plan"
					body_html={this.getContent()}
					cancel_btn={appstore.isOwner ? "Cancel":"Ok"}
					save_btn="Upgrade Now" 
					singleButton={!appstore.isOwner}
					callBack={() =>   this.homeComponentStore.openUpgrade(appstore)}
					closePopup={() => this.homeComponentStore.payDollar=false}
					appStore = {appstore}/>
				}
				{
				   this.homeComponentStore.errormodel &&
						<WarningComponent show={this.homeComponentStore.errormodel}
							store={this.homeComponentStore}
							cancel_btn="OKAY"
							closePopup={() => this.homeComponentStore.errormodel = false}
							appStore={appstore} />
				}

			</div>
		)
	}
})
export default HomeComponent;
